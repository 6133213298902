.quantity {
    display: flex;
    padding: 5px;
    border-radius: 15px;
}

.quantity .input-text.qty {
    font-size: var(--button-font-size);
    font-family: 'VarelaRound';
    padding: 0 5px;
    text-align: center;
    background-color: transparent;
    color: var(--secondary-color);
    border: none;

}

/* .quantity.buttons_added {
    text-align: left;
    position: relative;
    white-space: nowrap;
    vertical-align: top;
} */

.quantity.buttons_added input {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    box-shadow: none;
}

.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
    /* padding: 10px 20px; */
    font-family: 'VarelaRound';
    font-size:clamp(1rem, 1.1vw + .5rem, 1.8rem);;
    width: 20%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--secondary-color);
    border-radius: 100px;
}

.quantity.buttons_added .minus {
    border-right: 0;
}

.quantity.buttons_added .plus {
    border-left: 0;
}

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
    background: var(--accent-color);
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
    outline: none;
}