@font-face {
    font-family: 'VarelaRound';
    src: url('./fonts/VarelaRound-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Anton';
    src: url('./fonts/Anton-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


:root {
    --heading-font-size: clamp(1.2rem, 1.5vw, 2rem);
    --subheading-font-size: clamp(0.9rem, 1.1vw, 1.3rem);
    --paragraph-font-size: clamp(0.7rem, 1vw, 1.1rem);
    --button-font-size: clamp(0.9rem, 1.1vw, 1.3rem);

    --primary-color: #110F1D;
    --secondary-color: #ffffff      ;
    --accent-color: #ffffff;
}

h1 {
    font-size: var(--heading-font-size);
    font-weight: 600;
}

h2,
h3 {
    font-size: var(--subheading-font-size);
    font-weight: 400;
}

a {
    color: var(--secondary-color);
    font-weight: 400;
}

button {
    width: 60%;
    background-color: var(--primary-color);
    border: 2px solid var(--accent-color);
    border-radius: 10px;
    font-weight: 400;
    font-size: var(--button-font-size);
    color: var(--secondary-color);
    margin-block: max(.3vw, 5px);
    padding-block: max(.3vw, 5px);
    cursor: pointer;
}

button:hover {
    background-color: var(--accent-color);
    color: var(--primary-color);
    font-weight: 600;
}